const COMMAN_FIELDS = [
  "id",
  "user_id",
  "mobileNumber",
  "firstName",
  "lastName",
  "isCompanyName",
  "companyName",
  "address",
  "isEmail",
  "email",
  "selectedTemplateID",
  "servicesCount",
  "isCompanyLogo",
  "isWebsite",
  "websiteLink",
  "status",
  "isWhatsapp",
  "whatsAppLink",
  "isFacebook",
  "facebookLink",
  "isInstagram",
  "instagramLink",
  "isTwitter",
  "twitterLink",
  "isYouTube",
  "youTubeLink",
  "templateColor",
  "isProfileImage",
  "logoShape",
  "templateSecondaryColor",
  "description",
  "designation",
  "premium_plan",
  "plan_expired_datetime",
  "selected_plan",
  "reviews",
];

const IMAGE = [
  "id",
  "storage",
  "filename_disk",
  "filename_download",
  "title",
  "type",
  "folder",
  "filesize",
  "width",
  "height",
];

export const SVG = [
  "id",
  "storage",
  "filename_disk",
  "filename_download",
  "title",
  "type",
  "folder",
  "filesize",
];

export const SERVICE = ["id", "status", "name", ...SVG.map((item) => `svg.${item}`)];

export const QUERY_USER_TEMPLATE = {
  USER_BY_ID: [
    COMMAN_FIELDS,
    IMAGE.map((item) => `profile_image.${item}`),
    IMAGE.map((item) => `company_logo.${item}`),
    SERVICE.map((item) => `service1.${item}`),
    SERVICE.map((item) => `service2.${item}`),
    SERVICE.map((item) => `service3.${item}`),
    SERVICE.map((item) => `service4.${item}`),
  ],
  USER_BY_MOBILE_NUMBER: [
    COMMAN_FIELDS,
    IMAGE.map((item) => `profile_image.${item}`),
    IMAGE.map((item) => `company_logo.${item}`),
    SERVICE.map((item) => `service1.${item}`),
    SERVICE.map((item) => `service2.${item}`),
    SERVICE.map((item) => `service3.${item}`),
    SERVICE.map((item) => `service4.${item}`),
  ],
  TEMPLATE_USERDATA_ID: [
    COMMAN_FIELDS,
    IMAGE.map((item) => `profile_image.${item}`),
    IMAGE.map((item) => `company_logo.${item}`),
    SERVICE.map((item) => `service1.${item}`),
    SERVICE.map((item) => `service2.${item}`),
    SERVICE.map((item) => `service3.${item}`),
    SERVICE.map((item) => `service4.${item}`),
  ],
};