import axios from "axios";
import { axiosClient } from "../../../../setup/axios/SetupAxios";
import { TEMPLATES } from "../../../helper/APIURL";
import { TemplateListModel } from "../models/TemplateListModel";
import { API_URL } from "../../../helper/EnvVar";
import { QUERY_TEMPLATE } from "../../../helper/QueryOptimization/Template";

export const getTemplateList = () => {
  return axiosClient.get<TemplateListModel>(`${TEMPLATES}?fields=${QUERY_TEMPLATE.TEMPLATES}`);
};

export const getTemplateListForMobileUser = () => {
  return axios.get<TemplateListModel>(`${API_URL}${TEMPLATES}?fields=${QUERY_TEMPLATE.TEMPLATES}`);
};

export function getTemplateByID(id: string) {
  return axiosClient.get<TemplateListModel>(`${TEMPLATES}/${id}?fields=${QUERY_TEMPLATE.TEMPLATES}`);
}
