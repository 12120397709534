import React from "react";
import { useNavigate } from "react-router-dom";

const NotFoundComponent = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/home");
  };
  return (
    <>
      <div className="container">
        <div
          className="d-flex flex-column justify-content-center align-items-center "
          style={{ height: "90vh" }}
        >
          <div className="notfound-block text-center ">
            <div className="nor-found-img mb-5">
              <img
                src="/assets/images/not-found.png"
                alt="finest-img"
                width="320"
                height="273"
              />
            </div>
            <button
              className="btn btn-primary  fs-9 px-7 py-2" style={{borderRadius:'50px'}}
              onClick={handleClick}
            >
              Back To Home
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFoundComponent;
