
import Avatar from "@mui/material/Avatar";
import Slider from "@mui/material/Slider";
import React, { useEffect, useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import { Modal } from "react-bootstrap-v5";
import { dataURLtoFile } from "./utils/cropImage";
import { ResizeImage } from "./utils/ResizeImage";
import { addFiles } from "../../../../../../modules/template/redux/TemplateUserDataCRUD";
import { ASSEST_URL } from "../../../../../../helper/EnvVar";
import { FormattedMessage } from "react-intl";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";

export type DirectusFile = {
  data: {
      id: string,
      "storage": string,
      "filename_disk": string,
      "filename_download": string,
      "title": null,
      "type":  string,
      "folder": string,
      "uploaded_by": string,
      "uploaded_on": string,
      "modified_by": null,
      "modified_on": string,
      "charset": null,
      "filesize": number,
      "width": number,
      "height": number,
      "duration": null,
      "embed": null,
      "description": null,
      "location": null,
      "tags": null,
      "metadata": {
        //
      }
  }
}

type Props = {
  photoURL?: string;
  setOpenCrop?: boolean;
  show?: boolean;
  file?: File;
  cropperImageInput?: string | File;
  cropperProfileImageInput?: string | File;
  setUploadProfileImageFile?: (value: File) => void;
  uploadProfileImageFile?: DirectusFile | File | string;
  setCropperProfileImageInput?: () => void;
  setShowCropperFor?: (value: string) => void;
  setShowCroper?: (value: boolean) => void;
  profileImageIsLoading?: boolean;
  setProfileImageIsLoading: (value: boolean) => void;
  closeCropper?: boolean;
  base64String?: string;
  handleFileUpload?: () => void;
  existProfileImage?: string;
  existProfileImageName?: string;
  setChangeImageEvent: (value: boolean) => void;
  changeEvent?: boolean;
  setPreview: (value: string | undefined) => void;
  preview?: string;
  setModalOpen: (value: boolean) => void;
  src?: string;
  setSrc: (value: string | undefined) => void;
  onCropped?: (
    img: string,
    imgFor: string,
    croppedImgArea: null,
    cropFile: Blob,
    cropUrl: DirectusFile,
    name: File
  ) => void;
};

const CropProfileImage = ({
  src,
  setSrc,
  file,
  show,
  setModalOpen,
  setPreview,
  setChangeImageEvent,
  preview,
  setProfileImageIsLoading,
  cropperProfileImageInput,
  existProfileImageName,
  onCropped,
}: Props) => {
  const cropRef = useRef<typeof AvatarEditor>(null);
  const [image, setImage] = useState<string | undefined>();
  const [zoom, setZoom] = useState<number | number[]>(1.3);
  const [rotation, setRotation] = useState<number | number[]>(0);
  const [cancleValue, setHandleCancelValue] = useState<boolean>(false);
  const [croppedAreaPixels] = useState(null);

  const convertBlob = async () => {
    const result = await fetch(`${ASSEST_URL}/${cropperProfileImageInput}`);
    const blob = await result.blob(); 
    setImage(URL.createObjectURL(blob)) 
  }

  useEffect(() => {
    if(cropperProfileImageInput){
      convertBlob()
    }
  }, [cropperProfileImageInput]);

  //handle save
  const handleSave = async () => {
    try {
      if (cropRef) {
        const dataUrl = cropRef?.current?.getImage()?.toDataURL();
        const result = await fetch(dataUrl);
        const blob = await result.blob();
        setPreview(URL.createObjectURL(blob));
        setImage(URL.createObjectURL(blob));
        setModalOpen(false);
        setChangeImageEvent(false);
        setZoom(1.3);
        const nFile = dataURLtoFile(
          dataUrl,
          file?.name ? file?.name : existProfileImageName
        );
        const reSizeImage = await ResizeImage(nFile).then((res: unknown) => res);
        const resizeImageFormat = dataURLtoFile(
          reSizeImage,
          file?.name ? file?.name : existProfileImageName
        );
        const data = await addFiles(resizeImageFormat).then(
          (result) => result?.data
        );
        onCropped && onCropped(
          dataUrl,
          "profileImage",
          croppedAreaPixels && croppedAreaPixels,
          blob,
          data,
          nFile
        );
      }
    } catch (error) {
      toast.error("Please select/change image")
    }    
  };

  const handleAvtarChangeImage = async () => {
      try {
        if(!cancleValue){
          const dataUrl =
          cropRef?.current?.getImage()?.toDataURL();
          const result = await fetch(dataUrl);
          const blob = await result.blob();
          setPreview(URL.createObjectURL(blob));
        }else{
          setPreview(image)
          setHandleCancelValue(false)
        }
      } catch (error) {
        console.error(error)
      }
  };

  const onZoomChange = (zoom: number | number[]) => {
    setZoom(zoom);
    setHandleCancelValue(false)
  };

  const onRotation = (rotation: number | number[]) => {
    setRotation(rotation);
    setHandleCancelValue(false)
  };

  useEffect(()=> {
    handleAvtarChangeImage()
  },[zoom, rotation])

  const handleCancel = () => {
    setModalOpen(false);
    setChangeImageEvent(false);
    setProfileImageIsLoading(false);
    setHandleCancelValue(true)
    setPreview(image);
    setSrc(image);
    setImage(image);
    setZoom(1.3);
    setRotation(0)
  };

  const handleChangeInputFile = () => {
    setChangeImageEvent(true);
    const inp = document.getElementById("uploaderProfile");
    inp?.click();
  };

  const onHide = () => {
    // do functionality
  }

  return (
    <div className="overflow-hidden">
      <Modal
        className="modal-sticky modal-sticky-lg review-modal"
        id="kt_inbox_compose01"
        role="dialog"
        data-backdrop="false"
        aria-hidden="true"
        tabIndex="-1"
        show={show}
        onHide={onHide}
        animation={true}
      >
        {/*begin::Body*/}
        <div className="modal-body">
          {/*begin::Message*/}
          <div className="d-flex flex-column">
            <div className="master-container">
              <div className="crop-container">
                <AvatarEditor
                  ref={cropRef}
                  className="crop-content-center"
                  image={src ? src : image }
                  width={200}
                  height={200}
                  border={0}
                  style={{
                    borderColor: "#76747f",
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderRadius: "100px",
                  }}
                  borderRadius={100}
                  color={[255, 255, 255, 255]} // RGBA
                  scale={zoom}
                  rotate={rotation}
                  onMouseMove={handleAvtarChangeImage}
                />
              </div>
              <div className="controls">
                <div className="handle">
                  <span className="labels mb-2 d-flex"> <FormattedMessage 
                      id="cropmodal.zoom"
                      defaultMessage={"Zoom"}/> : </span>
                  <Slider
                    value={zoom}
                    min={1}
                    max={3}
                    step={0.01}
                    aria-labelledby="Zoom"
                    onChange={(e, zoom) => onZoomChange(zoom)}
                  />
                </div>
                <div className="handle">
                  <span className="labels mb-2 d-flex"> <FormattedMessage 
                      id="cropmodal.rotation"
                      defaultMessage={"Rotation"}/> : </span>
                  <Slider
                    valueLabelDisplay="auto"
                    min={0}
                    max={360}
                    value={rotation}
                    onChange={(e, rotation) => onRotation(rotation)}
                  />
                </div>
              </div>
            </div>
          </div>
          {/*end::Message*/}
        </div>

        {/*end::Body*/}
        {/*begin::Footer*/}
        <div className="py-4 container border-top">
          <div className="row align-items-end flex-sm-row flex-column">
            <div className="col">
              <div
                className="row align-items-center"
                style={{ paddingBottom: "10px" }}
              >
                <div className="col">
                  <Avatar
                    src={preview ? preview : image}
                    variant="circular"
                    style={{ width: "90px", height: "90px" }}
                  />
                </div>
                <div className="col text-sm-start text-center">
                  <Avatar
                    className="mx-sm-0 mx-auto"
                    src={preview ? preview : image}
                    variant="circular"
                    style={{ width: "60px", height: "60px" }}
                  />
                </div>
                <div className="col">
                  <Avatar
                    src={preview ? preview : image}
                    variant="circular"
                    style={{ width: "35px", height: "35px" }}
                  />
                </div>
              </div>
            </div>
            <div className="col">
              <div className="d-flex align-items-center w-100">
                <button
                  onClick={() => handleChangeInputFile()}
                  className="btn btn-primary w-100 mb-3"
                >
                  <FormattedMessage 
                      id="cropmodal.change.image"
                      defaultMessage={"Change Image"}/> 
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="d-flex align-items-center w-100">
                <button onClick={handleCancel} className="btn cta w-100">
                  <FormattedMessage 
                      id="cropmodal.cancel"
                      defaultMessage={"Cancel"}/> 
                </button>
              </div>
            </div>

            <div className="col">
              <div className="d-flex align-items-center w-100">
                <Button
                  name='button'
                  className="btn btn-primary w-100"
                  onClick={handleSave}
                >
                  <FormattedMessage 
                      id="cropmodal.save"
                      defaultMessage={"Save"}/>       
                </Button>
              </div>
            </div>
          </div>
        </div>
        {/*end::Footer*/}
      </Modal>
    </div>
  );
};

export default CropProfileImage;
