import React from "react";
import { ProtectedRoute } from "../../../components/utilities/ProtectedRoute";
import { Route, Routes, useLocation } from "react-router-dom";
import { PageDataContainer, PageTitle } from "../../../_start/layout/core";
import { profileBreadCrumbs } from "../../helper/BreadCrumbs";
import MobileProfilePreview from "../../../pages/MobileProfilePreview";
import NotFoundComponent from "../../pages/NotFound/NotFoundComponent";
import UserProfile from "../../../pages/UserProfile";
import ProfilePreview from "../../../pages/ProfilePreview";
import AboutPrint from "../../../pages/AboutPrint";

const ProfilePage: React.FC = () => {
  const { search } = useLocation()
  const queryString = search?.split('?')[1];
  const params = new URLSearchParams(queryString);
  const token = queryString?.split('&')[0];
  const language = params.get('language');
  const isAboutPrint = window.location.pathname.includes('/about-print');

  return (
    <>
      {search ?
        (<Routes>
          <Route path="/mobile-preview" element={<MobileProfilePreview token={token} language={language ? language : 'en'} />} />
          {isAboutPrint && token &&(
        <Route path="/about-print/:mobileNumber" element={<AboutPrint token={token} />} />
      )}
        </Routes>)
        : (
          <>
            {isAboutPrint ? (
              <>
                <PageDataContainer breadcrumbs={profileBreadCrumbs} />
                <PageTitle>Profile Overview</PageTitle>
                <Routes>
                  <Route path="*" element={<NotFoundComponent />}></Route>
                  <Route path="/about-print/:mobileNumber" element={<AboutPrint token={token} />} />
                </Routes>
              </>

            ) : (<ProtectedRoute>
              <>
                <PageDataContainer breadcrumbs={profileBreadCrumbs} />
                <PageTitle>Profile Overview</PageTitle>
                <Routes>
                  <Route path="*" element={<NotFoundComponent />}></Route>
                  <Route path="/user-profile" element={<UserProfile />} />
                  <Route path="/preview" element={<ProfilePreview />} />
                  {/* <Route path="/about-print/:mobileNumber" element={<AboutPrint token={""} />} /> */}
                </Routes>
              </>
            </ProtectedRoute>
            )}
          </>
        )}
    </>
  );

};

export default ProfilePage;
