
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { QRCodeSVG } from "qrcode.react";
import { PUBLIC_URL } from "../app/helper/EnvVar";
import { TemplateUserDataModel } from '../app/modules/template/models/TemplateUserDataModel';
import SVG from "react-inlinesvg";
import pocketlogo from "../templates/images/pocketsmall.svg";
interface PrintableModalProps {
    showModal: boolean;
    handleCloseModal: () => void;
    templateUserData: TemplateUserDataModel | undefined; 
    handlePrint: () => void;
}
const PrintableModal: React.FC<PrintableModalProps> = ({ showModal, handleCloseModal, templateUserData, handlePrint }) => {
    return (
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        dialogClassName="centered-modal"
        contentClassName="rounded-modal"
      >
        <Modal.Header closeButton className="custom-modal-header">
          <Modal.Title>My Pocketsite QR Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="printContent">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "100%",
              }}
            >
              <div
                style={{
                  color: templateUserData?.templateColor,
                  fontSize: "24px",
                }}
              >
                {templateUserData?.firstName} {templateUserData?.lastName}
              </div>
              <QRCodeSVG
                value={`${PUBLIC_URL}/${templateUserData?.mobileNumber}`}
                width="200"
                height="200"   
                level="M"
                style={{ marginTop: "20px" }}
                imageSettings={{
                    src: pocketlogo,
                    width: 26,
                    height: 26,
                    excavate: true
                  }
                }
              />
               <br />
              <table>
                <tr>
                  <td>Powered by :</td>
                  <td>
                    <span className="ps-2 pe-2">
                      <SVG
                        src={pocketlogo}
                        className="logosmall"
                        width={18}
                        height={18}
                      />
                    </span>
                  </td>
                  <td>PocketSite.me</td>
                </tr>
              </table> 
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button id="PS-printmodal-close-btn" variant="secondary" onClick={handleCloseModal} className="py-2 m-0 me-2"  style={{height:"48px"}}>
            Close
          </Button>
          <Button
            variant="primary"
            className="btn btn-success"
            onClick={handlePrint}
          >
            Print
          </Button>
        </Modal.Footer>
      </Modal>
    );
};

export default PrintableModal;