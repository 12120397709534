export const COMMON_FIELDS = [
  "id",
  "status",
  "name",
  "templateColor",
  "templateSecondaryColor",
];

export const IMAGE = [
  "id",
  "storage",
  "filename_disk",
  "filename_download",
  "title",
  "type",
  "folder",
  "filesize",
];

export const QUERY_TEMPLATE = {
  TEMPLATES: [
    COMMON_FIELDS,
    ...IMAGE.map((item) => `image.${item}`),
  ],
};
