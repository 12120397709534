import React, { Suspense, lazy, useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { ThemeProvider } from "../_start/layout/core";
import { FallbackView } from "../_start/partials";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthDataRedux } from "./modules/auth/redux/ReduxDispatchExport";
import { checkToken, tracker } from "./helper/helper";
import { PocketWebsite } from "../PocketWebsite";
import { RootState } from "../setup";
import NotFoundComponent from "./pages/NotFound/NotFoundComponent";
import Logout from "./modules/auth/Logout";
import Contactus from "./pages/Contact/Contactus";
import Razorpay from "./pages/Payment/Razorpay";
import Aboutus from "./pages/About/Aboutus";
import AboutusNew from "./pages/About/AboutusNew";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TermsCondition from "../pages/TermsCondition";
import AuthPage from "./modules/auth";
import EditTemplate from "./pages/templates/EditTemplate";
import ProfilePage from "./modules/profile/ProfilePage";
import Dashboard from "../pages/Dashboard";
import { EDIT_TEMPLATE_TOTAL_TIME_SPENT, PROFILE_PREVIEW_TOTAL_TIME_SPENT, WEBSITE_TOTAL_TIME_SPENT } from "./helper/EventsConst";

const Home = lazy(() => import(/* webpackChunkName: "home" */ "./pages/Home/Home"));

type Props = {
  basename:string 
}

const App: React.FC<Props>= () => {
  const dispatch = useDispatch();
  const [siteView, setSiteView] = useState<boolean>(false);
  const [startTimeProfilePreview, setStartTimeProfilePreview] = useState<string>();
  const [startTimeEditTemplate, setStartTimeEditTemplate] = useState<string>();
  const [endTimeProfilePreview, setEndTimeProfilePreview] = useState<string>();
  const [endTimeEditTemplate, setEndTimeEditTemplate] = useState<string>();

  const auth = useSelector((state: RootState) => state.auth);
  const { templateUserData } = useSelector((state: RootState) => state.templateUserData);  

  const url = window.location.href;
  const mobileNumberCheck = url.split("/");
  const mobileNumber = mobileNumberCheck[url.split("/").length - 1];

  useEffect(() => {
    const getTotalTimeSpent = () => {
      const totalTimeSpent = localStorage.getItem("totalTimeSpent");
      return totalTimeSpent ? parseInt(totalTimeSpent, 10) : 0;
    };

    const updateTotalTimeSpent = (totalTime: number, deviceID: string) => {
      localStorage.setItem("totalTimeSpent", totalTime.toString());
      tracker({
        eventName: WEBSITE_TOTAL_TIME_SPENT,
        dataInfo: {
          totalTimeSpent: totalTime.toString(),
          deviceId: deviceID
        }
      })
    };

    const handleBeforeUnload = () => {
      const endTime = new Date();
      const startTime = new Date(localStorage.getItem("startTime"));

      const timeSpentOnPage = endTime - startTime;
      const userAgent = window.navigator.userAgent;
      const platform = window.navigator.platform;
      const randomString = Math.random().toString(20).substring(2, 14) + Math.random().toString(20).substring(2, 14);
    
      const deviceID = `${userAgent}-${platform}-${randomString}`;
          
      const totalTimeSpent = getTotalTimeSpent() + timeSpentOnPage;
      updateTotalTimeSpent(totalTimeSpent, deviceID);
    };

    // Set the start time only if it doesn't exist in sessionStorage
    if (!localStorage.getItem("startTime")) {
      localStorage.setItem("startTime", new Date().toString());
    }

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if(mobileNumberCheck[3] === "profile  "){
      setStartTimeProfilePreview(new Date().toLocaleTimeString())
    }else{
      setEndTimeProfilePreview(new Date().toLocaleTimeString())
    }
    if(mobileNumberCheck[3] === "edit-template"){
      setStartTimeEditTemplate(new Date().toLocaleTimeString())
    }else{
      setEndTimeEditTemplate(new Date().toLocaleTimeString())
    }
  },[window.location.href])

  // Function to calculate time difference
  const calculateTimeDifference = (startTime: string, endTime: string) => {
    const start = new Date(`2023-01-01 ${startTime}`);
    const end = new Date(`2023-01-01 ${endTime}`);
    const difference = end - start;

    // Convert the time difference to the desired format (e.g., seconds)
    const secondsDifference = Math.floor(difference / 1000);

    return secondsDifference;
  };

  // Calculate and log the time differences
  useEffect(() => {
    if (startTimeProfilePreview && endTimeProfilePreview) {
      const timeSpentOnProfilePreview = calculateTimeDifference(
        startTimeProfilePreview,
        endTimeProfilePreview
      );
      if(timeSpentOnProfilePreview > 0){
        tracker({
         eventName: PROFILE_PREVIEW_TOTAL_TIME_SPENT,
         dataInfo: {
            userName: templateUserData?.firstName + " " + templateUserData?.lastName,
            mobileNumber: templateUserData?.mobileNumber,
            totalTimeSpent: `${timeSpentOnProfilePreview} sec-${templateUserData?.mobileNumber}`
          }
        })
      }
    }
  }, [startTimeProfilePreview, endTimeProfilePreview]);
  
  // Calculate and log the time differences
  useEffect(() => {
    if (startTimeEditTemplate && endTimeEditTemplate) {
      const timeSpentOnEditTemplate = calculateTimeDifference(
        startTimeEditTemplate,
        endTimeEditTemplate
      );     
      if(timeSpentOnEditTemplate>0){
        tracker({
          eventName: EDIT_TEMPLATE_TOTAL_TIME_SPENT,
          dataInfo: {
            userName: templateUserData?.firstName + " " + templateUserData?.lastName,
            mobileNumber: templateUserData?.mobileNumber,
            totalTimeSpent: `${timeSpentOnEditTemplate} sec-${templateUserData?.mobileNumber}`
          } 
        })
      }
    }
  }, [startTimeEditTemplate, endTimeEditTemplate]);

  useEffect(() => {
    if(mobileNumberCheck[4] === 'about-print'){
      setSiteView(false)
    }else{
      if(mobileNumber.length >= 10) {
        if(/^[0-9]*$/.test(`${mobileNumber}`)){
          setSiteView(true);
        }
      }else{
        if (/^\d{10}$/.test(`${mobileNumber}`)) {
          setSiteView(true);
        }
        else{
          setSiteView(false)
        }
      }
    }
  }, [mobileNumber]);

  window?.addEventListener('load', function() {
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap";
    link.crossOrigin = "anonymous";
    document.head.appendChild(link);
  })

  useEffect(() => {
    if (checkToken(auth)) {
      dispatch(AuthDataRedux.actions.logout());
    }
  }, [auth.isAuthorized]);

  return (
    <>
        {siteView ? (
          <>
            <PocketWebsite mobileNumber={mobileNumber} />
          </>
        ) : (
          <ThemeProvider>
            <Suspense fallback={<FallbackView />}>
              <Routes>
                 <Route
                  path="*"
                  element={auth?.isAuthorized ? <></> : <></>}
                ></Route>
                <Route
                  path="*"
                  element={!auth?.isAuthorized ? <></> : <NotFoundComponent />}
                ></Route> 
                <Route
                  path="/home"
                  element={
                    !auth?.isAuthorized ? (
                      <Home />
                    ) : (
                      <Navigate replace to="/profile/preview" />
                    )
                  }
                ></Route>
                <Route
                  path="/home/:section"
                  element={
                    !auth?.isAuthorized ? (
                      <Home />
                    ) : (
                     <Navigate replace to="/profile/preview" />
                    )
                  }
                ></Route>
                <Route
                  path="/"
                  element={
                    !auth?.isAuthorized ? (
                      <Home />
                    ) : (
                     <Navigate replace to="/profile/preview" />
                    )
                  }
                ></Route>
                <Route path="/logout" element={<Logout />} />
                <Route path="/contactus" element={<Contactus  />}></Route>
                <Route path="/razorpay" element={<Razorpay />}></Route>
                <Route path="/aboutus" element={<Aboutus />}></Route>
                <Route path="/aboutus-new" element={<AboutusNew />}></Route>
                <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
                <Route
                  path="/terms-condition"
                  element={<TermsCondition />}
                ></Route>
                <Route path="auth/*" element={<AuthPage />} />
                <Route path="/login" element={<AuthPage />} />
                <Route path="/edit-template/:id" element={<EditTemplate />} />
                <Route path="/profile/*" element={<ProfilePage />} />
                <Route path="/dashboard" element={<Dashboard />} />
              </Routes>
            </Suspense>
          </ThemeProvider>
        )}
        <ToastContainer
          containerId="toast-id"
          position="top-center"
          autoClose={10000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
    </>
  );
};

export { App };
