import React from "react";
import { QRCodeSVG } from "qrcode.react";
import pocketlogo from "../templates/images/pocketsmall.svg";

type QRCodeProps = {
  value: string;
  profileImage?: boolean;
  companyLogo?: boolean;
};

function QRCodeComponent({ value }: QRCodeProps) {
  return (
    <QRCodeSVG
      value={value}
      width="70"
      height="70"
      level="M"
      imageSettings={{
        src: pocketlogo,
        width: 26,
        height: 26,
        excavate: true,
      }}
    />
  );
}

export default QRCodeComponent;
