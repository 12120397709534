import store from "../../setup/redux/Store";
import { createCanvas, loadImage } from 'canvas';
import { NODE_ENV, PUBLIC_URL } from "./EnvVar";
import { TemplateUserDataModel } from "../modules/template/models/TemplateUserDataModel";
import { ObjProps, ServicesListModel } from "../modules/template/models/ServicesModel";
import { IAuthState } from "../modules/auth/redux/AuthRedux";
import { FormikErrors } from "formik/dist/types";

export const mobNum = /^[1-9][0-9]{9}$/;
export const exclude0 = /^([1-9][0-9]{9})|([0][1-9][0-9]{9})$/;
export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const urlRegExp =
  /^(https?:\/\/)?([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,6})(\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=]*)?(\/[^.]+)?$/

export function normalizeURL(inputURL: string) {
    const url = inputURL?.trim()?.toLowerCase();
    const regex = /^(https?):\/\/(www\.)?([-a-zA-Z0-9@:%._~#=]{2,256}\.[a-zA-Z]{2,20})([-a-zA-Z0-9@:%_.~#?&//=]*)$/;
  
    const match = url.match(regex);
    if (!match) {
      return null; // Invalid URL
    }
  
    const protocol = match[1].toLowerCase();
    const domain = match[3].toLowerCase();
    const restOfURL = match[4];
  
    const normalizedURL = `${protocol}://${domain}${restOfURL}`;
    return normalizedURL;
  }

export const firstCharCapital = (str: string | undefined) => str && str.charAt(0).toUpperCase() + str.slice(1) 

export const valueToIDresolveAsyncSelect = (obj: ObjProps[]): { id: string }[] => {
  const newSelectedArray: { id: string }[] = [];
  if (obj && obj.length > 1) {
    for (const key of obj) {
      newSelectedArray.push({ id: key.value });
    }
    return newSelectedArray;
  } else if (obj && obj.length === 1) {
    newSelectedArray.push({ id: obj[0].value });
    return newSelectedArray;
  } else {
    return newSelectedArray;
  }
};

export const iDToValueResolveSelect = (obj?: ServicesListModel[]): { value: string, label: string, svg?: string }[] => {
  const newSelectedArray:  { value: string, label: string, svg?: string }[] = [];
  if (obj && obj.length > 1) {
    for (const key of obj) {
      newSelectedArray.push({ value: key.id.toString(), label: key.name, svg: key.svg });
    }
    return newSelectedArray;
  } else if (obj && obj.length === 1) {
    newSelectedArray.push({
      value: obj[0].id.toString(),
      label: obj[0].name,
      svg: obj[0].svg,
    });
    return newSelectedArray;
  } else {
    return newSelectedArray;
  }
};


   // Function to preprocess the image and set transparency
export const preprocessImage = async(src: string): Promise<string> => {
    const image = await loadImage(src);
    const canvas = createCanvas(image.width, image.height);
    const context = canvas.getContext('2d');
    // Set transparent background
    context.clearRect(0, 0, canvas.width, canvas.height);
    // Draw the image on the canvas
    context.drawImage(image, 0, 0);
    // Convert the canvas to a data URL with transparency
    const dataUrl = canvas.toDataURL('image/png');

    return dataUrl;
  };


export const imageToBase64 = (url: string, callback: (result : string | ArrayBuffer | null) => void) => {
  const xhr = new XMLHttpRequest();
  xhr.onload = function () {
    const reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open("GET", url);
  xhr.responseType = "blob";
  xhr.send();
};

export const parseUrl = (url: string) => {
  if (url.includes("http") || url.includes("https")) {
    return url;
  } else {
    return `https://${url}`;
  }
};

export const ImageCropped = (croppedArea: string) => {
  const croppedObj = JSON.parse(croppedArea);
  const scale = 100 / croppedObj?.width;
  const transform = {
    x: `${-croppedObj?.x * scale}%`,
    y: `${-croppedObj?.y * scale}%`,
    scale,
    width: "100",
    height: "100",
  };
  const imageStyle = {
    transform: `translate3d(${0}, ${0}, 0) scale3d(${transform.scale},${
      transform.scale
    },1)`,
    width: transform.width,
    height: transform.height,
  };
  return imageStyle;
};

export const socialSharing = async (templateUserData: TemplateUserDataModel | undefined, fallback: () => void): Promise<void> => {

  const shareDetails = {
    url: `${PUBLIC_URL}/${templateUserData?.mobileNumber}`,
    title: `${templateUserData?.firstName} ${templateUserData?.lastName}`,
    text: `
    My Services: 
    ${templateUserData?.service1?.name ? `${templateUserData?.service1?.name}\n` : ''}${templateUserData?.service2?.name ? `${templateUserData?.service2?.name}\n` : ''}${templateUserData?.service3?.name ? `${templateUserData?.service3?.name}\n` : ''}${templateUserData?.service4?.name ? `${templateUserData?.service4?.name}\n` : ''}`,
  };
  if (navigator.share) {
    try {
      return await navigator.share(shareDetails);
    } catch (err) {
      console.error(err);
    }
  } else {
    // fallback code
    fallback();
  }
};

export const checkToken = (auth: IAuthState): boolean | undefined => {
  if (auth.isAuthorized) {
    const mystate = store.getState();
    if (mystate.auth.refreshToken) {
      if(auth.accessToken){
        const token: { exp: number } = JSON.parse(window.atob(auth.accessToken.split(".")[1]));
        if (token.exp * 1000 < Date.now()) {
          return true;
        } else {
          return false;
        }
      }
    }
  }
};

export function isNotEmptyObject(value: FormikErrors<unknown>) {
  return typeof value === 'object' && !Array.isArray(value) && Object.keys(value).length > 0;
}

type DataInfo = {
  userName?: string;
  mobileNumber?: string;
  message?: string;
  templateId?: number;
  tabName?: string;
  color?: string;
  websiteViewfrom?: string;
  defaultColor?: string;
  totalTimeSpent?: string;
  deviceId?: string;
  selectedLanguage?: string;
}

type WindowWithDataLayer = Window & {
  gtag: (event: string, eventName: string, dataInfo: DataInfo | undefined) => void;
};

declare const window: WindowWithDataLayer;

type TrackerProps = {
  eventName: string;
  dataInfo?: DataInfo;
};

export const tracker = ({ eventName, dataInfo }: TrackerProps) => {
  NODE_ENV === "production" && window.gtag('event', eventName, dataInfo);
};