import React, { useEffect, useState }  from 'react';
import SVG from "react-inlinesvg";
import { Overlay, Tooltip } from 'react-bootstrap'; 
import downloadCard from "../templates/images/icons/downloadcard.svg"

type TooltipButtonProps = {
  buttonRef: React.RefObject<HTMLButtonElement>;
  onClick: () => void;
};

  

const ToolTipGuide: React.FC<TooltipButtonProps> = ({ buttonRef, onClick }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    useEffect(() => {
        setTimeout(() => {
          setShowTooltip(true);
    
          setTimeout(() => {
            setShowTooltip(false);
          }, 2000);
        }, 1000);
      }, []);
  return (
    <div className="d-flex justify-content-center text-center">

                      <button
                        ref={buttonRef}
                        type="button"
                        className="addcontact-btn"
                        aria-label="addcontact-btn"
                        onClick={onClick}
                        
                      >
                        <SVG src={downloadCard} width="32" height="18" />
                      </button>

                      <Overlay show={showTooltip} target={buttonRef.current}>
                        <Tooltip id="download-tooltip">Download File</Tooltip>
                      </Overlay>
                    </div>
  );
};

export default ToolTipGuide;
