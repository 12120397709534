import FileSaver from "file-saver";
import { getVCFFile } from "./app/modules/template/redux/TemplateUserDataCRUD";
import { TemplateUserDataModel } from "./app/modules/template/models/TemplateUserDataModel";
import { tracker } from "./app/helper/helper";
import { SHAREBLE_URL_ADD_CONTACT_BUTTON_CLICK } from "./app/helper/EventsConst";
 
export const downloadVCFFile = async (data: TemplateUserDataModel | undefined) => {
    const vcfFile = data && await getVCFFile(data).then(res => res).catch(err => err);
    localStorage.setItem('vcf-download', 'true')
    const element = document.createElement("a");
    element.download = `${data?.firstName}${data?.lastName}.vcf`;
    const blob = new Blob([vcfFile?.data?.vcf], { type: "text/vcard;charset=utf-8" })
    FileSaver.saveAs(
        blob,
        `${data?.firstName}${data?.lastName}.vcf`,
        true
      );
    document.body.appendChild(element);
    tracker({eventName:SHAREBLE_URL_ADD_CONTACT_BUTTON_CLICK, dataInfo: {
      mobileNumber: data?.mobileNumber,
    }})
    element.click();
}