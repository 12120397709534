import * as Yup from "yup";
import {  mobNum, urlRegExp } from "../../../helper/helper";
import { getTranslatedMessage } from "../../../../wrapper/Wrapper";

export const TemplateValidate = (lang : string | undefined) =>{  return Yup.object().shape(
  {
    profile_image: Yup.mixed().test('isStringOrObject', getTranslatedMessage("error.message.profileimage", "Profile Image Required",lang), value => {
      return Yup.string().isValidSync(value) || Yup.object().isValidSync(value);
    }).required(getTranslatedMessage("error.message.profileimage", "Profile Image Required",lang)).nullable(),
    company_logo: Yup.mixed().test('isStringOrObject', getTranslatedMessage("error.message.companylogo.switch", "Please Select Company Logo, or Disable it.",lang), function (value) {
      const { isCompanyLogo } = this.parent;
      if (isCompanyLogo) {
        return Yup.string().isValidSync(value) || Yup.object().isValidSync(value);
      }
      return true;
    }).nullable(),
    firstName: Yup.string()
      .min(2, getTranslatedMessage("error.message.min2", "Minimum 2 Character",lang))
      .max(20, getTranslatedMessage("error.message.max20", "Maximum 20 Characters",lang))
      .trim('Not contain blank space')
      .required(getTranslatedMessage("error.message.firstname", "First Name is required",lang)).nullable(),
    lastName: Yup.string()
    .min(2, getTranslatedMessage("error.message.min2", "Minimum 2 Character",lang))
    .max(20, getTranslatedMessage("error.message.max20", "Maximum 20 Characters",lang))
    .trim('Not contain blank space')
    .required(getTranslatedMessage("error.message.lastname", "Last Name is required",lang)).nullable(),
    companyName: Yup.string()
      .min(5, getTranslatedMessage("error.message.min5", "Minimum 5 Character are Required",lang))
      .max(80, getTranslatedMessage("error.message.max80", "Maximum 80 Character are Required",lang))
      .when("isCompanyName", {
        is: (value: boolean) => value,
        then: (rule) => rule.required(getTranslatedMessage("error.message.companyname", "Company Name is Required",lang)),
      })
      .nullable(),
    designation: Yup.string()
      .min(2, getTranslatedMessage("error.message.min2", "Minimum 2 Character",lang))
      .max(50, getTranslatedMessage("error.message.max50", "Maximum 50 Character",lang))
      .trim('Not contain blank space')
      .required(getTranslatedMessage("error.message.designation", "Designation is Required",lang)).nullable(),
    email: Yup.string()
      .email(getTranslatedMessage("error.message.wrongemail", "Wrong email format",lang))
      .when("isEmail", {
        is: (value: boolean) => value,
        then: (rule) => rule.required(getTranslatedMessage("error.message.email", "Email is Required",lang)),
      })
      .required(getTranslatedMessage("error.message.email", "Email is Required",lang))
      .nullable(),
    mobileNumber: Yup.string()
      .min(
        10,
         getTranslatedMessage("error.message.mobilenumbervaild10digit", "Please enter a valid 10 digit mobile number, without special characters and spaces",lang)
     )
      .trim('Not contain blank space')
      .required(getTranslatedMessage("error.message.mobilenumber", "Mobile number is required",lang))
      .matches(
        mobNum,
        getTranslatedMessage("error.message.mobilenumber.whitespace", "Mobile number is not valid, Cannot contain special characters or spaces",lang)
      ),
    websiteLink: Yup.string()
      .when("isWebsite", {
        is: (value: boolean) => value,
        then: (rule) =>
          rule.required(getTranslatedMessage("error.message.website", "Website Link is Required",lang))
      })
      .trim('Not contain blank space')
      .matches(urlRegExp, getTranslatedMessage("error.message.validwebsite", "Enter Qualified Website Link! e.g example.com",lang))
      .nullable(),
    address: Yup.string()
      .min(10, getTranslatedMessage("error.message.min10", "Minimum 10 Character",lang))
      .max(150, getTranslatedMessage("error.message.max150", "Maximum 150 Character",lang))
      .trim('Not contain blank space')
      .required(getTranslatedMessage("error.message.address", "Address is Required",lang)).nullable(),
    description: Yup.string()
      .min(30, getTranslatedMessage("error.message.min30", "Minimum 30 Character",lang))
      .max(150, getTranslatedMessage("error.message.max150", "Maximum 150 Character",lang))
      .trim('Not contain blank space')
      .required(getTranslatedMessage("error.message.description", "Description is Required",lang)).nullable(),
    whatsAppLink: Yup.string()
      .min(
        10,
        getTranslatedMessage("error.message.mobilenumbervaild10digit", "Please enter a valid 10 digit mobile number, without special characters and spaces",lang)
      )
      .required(getTranslatedMessage("error.message.whatsappnumber", "Whatsapp number is required",lang))
      .trim('Not contain blank space')
      .matches(
        mobNum,
        getTranslatedMessage("error.message.mobilenumber.whitespace", "Mobile number is not valid, Cannot contain special characters or spaces",lang)
      ).nullable(),
    facebookLink: Yup.string()
      .when("isFacebook", {
        is: (value: boolean) => value,
        then: (rule) =>
          rule.required(getTranslatedMessage("error.message.facebook", "Facebook Link is Required",lang)),
      }).when("isFacebook", {
        is: (value: boolean) => value,
        then: (rule) =>
          rule
          .matches(urlRegExp, getTranslatedMessage("error.message.validurl", "Enter correct url!",lang)),
      })
      .trim('Not contain blank space')
      .nullable(),
    instagramLink: Yup.string()
      .when("isInstagram", {
        is: (value: boolean) => value,
        then: (rule) =>
          rule.required(getTranslatedMessage("error.message.instagram", "Instagram Link is Required",lang)),
      }).when("isInstagram", {
        is: (value: boolean) => value,
        then: (rule) =>
          rule.matches(urlRegExp, getTranslatedMessage("error.message.validurl", "Enter correct url!",lang)),
      })
      .trim('Not contain blank space')
      .nullable(),
    twitterLink: Yup.string()
      .when("isTwitter", {
        is: (value: boolean) => value,
        then: (rule) =>
          rule.required(getTranslatedMessage("error.message.twitter", "Twitter Link is Required",lang)),
      }).when("isTwitter", {
        is: (value: boolean) => value,
        then: (rule) =>
          rule.matches(urlRegExp, getTranslatedMessage("error.message.validurl", "Enter correct url!",lang)),
      })
      .trim('Not contain blank space')
      .nullable(),
    youTubeLink: Yup.string()
      .when("isYouTube", {
        is: (value: boolean) => value,
        then: (rule) =>
          rule.required(getTranslatedMessage("error.message.youtube", "Youtube Link is Required",lang)),
      }).when("isYouTube", {
        is: (value: boolean) => value,
        then: (rule) =>
          rule.matches(urlRegExp, getTranslatedMessage("error.message.validurl", "Enter correct url!",lang)),
      })
      .trim('Not contain blank space')
      .nullable(),
    isFacebook: Yup.boolean(),
    isInstagram: Yup.boolean(),
    isTwitter: Yup.boolean(),
    isYouTube: Yup.boolean(),
  },
  [
    // .matches(urlRegExp, "Enter correct url!")
    // Add Cyclic deps here because when require itself
    ["isEmail", "isWebsite"],
  ]
);}