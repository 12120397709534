import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import InputField from "../edit-template/InputField";
import { addSubscribers } from "../../app/modules/template/redux/TemplateUserDataCRUD";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import { InitSubscribeForm } from "../../app/pages/templates/components/Templates/common/subscribeHelper/InitSubscribeFormModal";
import { SubscribeFormValidate } from "../../app/pages/templates/components/Templates/common/subscribeHelper/SubscribeFormValidate";
import { FormattedMessage } from "react-intl";
import { Button } from "react-bootstrap-v5";
import { HOME_SUBSCRIPTION_CLICK } from "../../app/helper/EventsConst";
import { tracker } from "../../app/helper/helper";
import { SubscribersFormModel } from "../../app/pages/templates/components/Templates/common/subscribeHelper/SubcribersModal";
import { FormikHelpers } from "formik/dist/types";

function StayConnected() {
  const [loading, setLoading] = useState(false);
  const [sloading, setsLoading] = useState(false)
  useEffect(() => {
    const timer = setTimeout(() => {
      setsLoading(false);
    }, 2000)
    return () => clearTimeout(timer);
  }, [])

  const formik = useFormik({
    initialValues: InitSubscribeForm,
    validationSchema: SubscribeFormValidate,
    onSubmit: (values: SubscribersFormModel, { setSubmitting, resetForm }: FormikHelpers<SubscribersFormModel>) => {
      setLoading(true);
      if (values) {
        addSubscribers({
          ...values, status: "Published"
        })
          .then(() => {
            setSubmitting(false);
            toast.success(<FormattedMessage id="toast.message.success.subscribing" defaultMessage={"Success...! Thank you for subscribing."} />);
            setLoading(false);
            resetForm();    
          })
          .catch((e) => {
            setSubmitting(false);
            console.error(e)
            console.log("Error...! Something went wrong");
            setLoading(false);
          });
      }
    }
  });

  const TrackGASubscribeButtonClick=()=>{
    tracker({eventName:HOME_SUBSCRIPTION_CLICK})
  }
  return (
    <>
      <div className="stay-connected-section" id="contacts">
        <div className="container">
          <div className="text-center">
            {sloading ? (
              <Skeleton
                height={64}
                width={500}
                style={{ marginTop: "30px" }}
                baseColor="#ebebeb"
                highlightColor="#d3d3d3 "
              />
            ) : (
              <h2 className="heading-medium">
                <FormattedMessage
                  id="home.stay.connected"
                  defaultMessage={"Stay connected with us"}
                />
              </h2>
            )}
            {sloading ? (
              <Skeleton
                height={32}
                width={600}
                style={{ marginTop: "16px" }}
                baseColor="#ebebeb"
                highlightColor="#d3d3d3 "
              />
            ) : (
              <p className="mb-40">
                <FormattedMessage
                  id="home.ignite.sucess"
                  defaultMessage={
                    "Ignite your success: Subscribe to our newsletter and stay ahead of the game!"
                  }
                />
              </p>
            )}
          </div>
          <Form className="mx-auto" onSubmit={formik.handleSubmit}>
            <div className="d-flex justify-content-center stay-connected-form">
              {sloading ? (
                <Skeleton height={48} width={548} style={{ marginTop: "40px" }} baseColor="#ebebeb" highlightColor="#d3d3d3 " />
              ) :
                <div>
                  <div className="floating-group mb-sm-0 mb-4 ">
                    <InputField
                      id="email"
                      type="email"
                      className="mb-0 w-100"
                      placeholder=" "
                      name="email"
                      autoFocus={false}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      onFocus={formik.handleBlur}
                      value={formik?.values?.email}
                    />
                    <label className="float-label" htmlFor="email"><FormattedMessage
                      id="home.your.email"
                      defaultMessage={"Your email here"}
                    /></label>
                    {formik.touched.email && formik.values.email === "" && (
                  <div style={{color:"#d15a5a",fontSize:"14px",marginTop:"4px"}}>
                    <FormattedMessage
                      id="error.message.email"
                      defaultMessage={"Email Is Required"}
                    />
                  </div>
                )}
                {formik.touched.email && formik.errors.email && formik.values.email !== "" && (
                  <div style={{color:"#d15a5a",fontSize:"14px",marginTop:"4px"}}>
                    <FormattedMessage
                      id="error.message.wrongemail"
                      defaultMessage={"Wrong email format"}
                    />
                  </div>)}
                  </div>
                  
                </div>
              }

              {sloading ? (
                <Skeleton height={48} width={150} style={{ marginTop: "40px", marginLeft: "20px" }} baseColor="#ebebeb" highlightColor="#d3d3d3 " />
              ) :
                loading ?
                  <Button
                    name="button"
                    className="ms-sm-3 px-4"
                    type="submit">
                    <FormattedMessage
                      id="edit.template.pleasewait"
                      defaultMessage={"Please Wait..."}
                    />
                  </Button> :
                  <div>

                    <Button name="button" className="ms-sm-3 px-4" type="submit" 
                    onClick={TrackGASubscribeButtonClick}
                    >
                      <FormattedMessage
                        id="home.subscribe"
                        defaultMessage={"Subscribe"}
                      />
                    </Button>
                  </div>
              }
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}

export default StayConnected;
