import React from "react";
import Button from "react-bootstrap/Button";
import { FormattedMessage } from "react-intl";
type Props = {
  buttonName: string;
  btnText: string;
  className: string;
  id?: string;
  disabled?: boolean;
  hidden?: boolean;
  type?: "button" | "submit" | "reset";
  style?: React.CSSProperties | undefined;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};
const Buttons: React.FC<Props> = ({
  buttonName,
  className,
  type,
  id,
  style,
  disabled,
  hidden,
  onClick,
}) => {
  return (
    <Button
      type={type}
      name={buttonName}
      className={className}
      id={id}
      hidden={hidden}
      style={style}
      disabled={disabled}
      onClick={onClick}
    >
      <FormattedMessage
        id="preview.share"
        defaultMessage={"Share"}
      />
    </Button>
  );
};

export default Buttons;
