export const EDIT_TEMPLATE_TIME_SPENT = 'time_spent_on_edit_template'
export const SHAREABLE_URL_IMPRESSION = 'view_shareable_url_page'
export const HOME_CREATE_NOW_BUTTON_CLICK = 'create_now_button_click'
export const HOME_CREATE_YOURS_BUTTON_CLICK ='create_yours_button_click'
export const HOME_START_NOW_BUTTON_CLICK = 'start_now_button_click'
export const HOME_SPECIAL_DESIGN_CARD_CLICK ='home_special_design_card_click'
export const HOME_SUBSCRIPTION_CLICK = 'home_subscription_click'
export const SIDEBAR_LOGOUT_CLICK ='sidebar_logout_button_click'
export const SIDEBAR_PROFILE_TAB_CLICK ='sidebar_profile_tab_click'
export const SIDEBAR_CARDSTAB_CLICK ='sidebar_cardstab_click'
export const HEADER_ALL_TAB_CLICK ='header_all_tab_click'
export const LOGIN_POCKETSITE_ICON_CLICK ='login_pocketsite_icon_click'
export const LOGIN_OTP_BUTTON_CLICK ='login_otp_button_click'
export const PROFILE_PREVIEW_DEFAULT_COLOR_CLICK ='profile_preview_default_color_click'
export const PROFILE_PREVIEW_CHANGE_TEMPLATE_CLICK ='profile_preview_change_template_click'
export const PROFILE_SHARE_BUTTON_CLICK = 'profile_preview_change_button_click'
// export const EDIT_TEMPLATE_NEW_PROFILE_CREATED = 'edit_template_new_profile_created'
export const EDIT_TEMPLATE_NEW_PROFILE_CREATED = 'ET_NPC'
export const EDIT_TEMPLATE_PROFILE_EDITED = 'edit_template_profile_updated'
export const EDIT_TEMPLATE_COLOR_CHANGE = 'edit_template_color_change'
export const PREVIEW_TEMPLATE_COLOR_CHANGE = 'preview_template_color_change'
export const EDIT_TEMPLATE_NEXT_CLICK = 'edit_template_next_click'
export const EDIT_TEMPLATE_PREVIOUS_CLICK = 'edit_template_previous_click'
export const PROFILE_PREVIEW_TEMPLATE_VISIT = 'profile_preview_template_visit'
export const WEBSITE_LOCATION = 'website_location'
export const PROFILE_PREVIEW_TOTAL_TIME_SPENT = 'profile_preview_total_time_spent'
export const EDIT_TEMPLATE_TOTAL_TIME_SPENT = 'edit_template_total_time_spent'
export const SHARE_MODAL_VIEW_URL_CLICK ='share_modal_view_url_click'
export const SHARE_MODAL_SHARE_URL_CLICK = 'share_modal_share_url_click'
export const SHARE_MODAL_SOCIAL_ICON_Whatsap_CLICK ='share_modal_social_icon_whataap_click'
export const SHARE_MODAL_SOCIAL_ICON_twitter_CLICK ='share_modal_social_icon_twitter_click'
export const SHARE_MODAL_SOCIAL_ICON_facebook_CLICK ='share_modal_social_icon_facebook_click'
export const HEADER_LANGUAGE_CHANGE = 'header_language_change'
export const SIDEBAR_LANGUAGE_CHANGE ='sidebar_language_change' 
export const SHAREBLE_URL_SOCIAL_TEMP06_WHATSAAP_ICON_CLICK ='shareble_url_social_temp06_whatsaap_icon_click'
export const SHAREBLE_URL_SOCIAL_TEMP06_YOUTUBE_ICON_CLICK = 'shareble_url_social_temp06_youtube_icon_click';
export const SHAREBLE_URL_SOCIAL_TEMP06_INSTAGRAM_ICON_CLICK = 'shareble_url_social_temp06_instagram_icon_click';
export const SHAREBLE_URL_SOCIAL_TEMP06_FACEBOOK_ICON_CLICK = 'shareble_url_social_temp06_facebook_icon_click';
export const SHAREBLE_URL_SOCIAL_TEMP06_TWITTER_ICON_CLICK = 'shareble_url_social_temp06_twitter_icon_click';
export const SHAREBLE_SHARE_ICON_CLICK = 'shareble_share_icon_click'
export const SHAREBLE_REVIEW_ICON_CLICK = 'shareble_review_icon_click'
export const SHAREBLE_URL_QR_CODE_ICON = 'shareble_url_qr_code_icon'
export const SHAREBLE_URL_ADD_CONTACT_BUTTON_CLICK = 'shareble_url_add_contact_button_click'
export const SHAREBLE_URL_CREATE_YOURS_BUTTON_CLICK ='shareble_url_create_yours_button_click'
export const SHAREBLE_URL_POCKETSITE_ICON_CLICK ='shareble_url_pocketsite_icon_click'
export const SHARE_URL_PHONE_NUMBER_CLICK ='share_url_phone_number_click'
export const SHARE_URL_WEBLINK_CLICK = 'share_url_weblink_click'
export const SHARE_URL_EMAIL_LINK_TEMP06_CLICK ='share_url_email_link_temp06_click';
export const SHARE_URL_LOCATION_TEMP06_CLICK = 'share_url_location_temp06_click';
export const WEBSITE_TOTAL_TIME_SPENT = 'website_total_time_spent';

// teamplate07 events
export const VIEW_TEMPLATE07 = 'view_template07'
export const SHAREBLE_URL_SOCIAL_TEMP07_WHATSAAP_ICON_CLICK ='shareble_url_social_temp07_whatsaap_icon_click';
export const SHAREBLE_URL_SOCIAL_TEMP07_YOUTUBE_ICON_CLICK = 'shareble_url_social_temp07_youtube_icon_click';
export const SHAREBLE_URL_SOCIAL_TEMP07_INSTAGRAM_ICON_CLICK = 'shareble_url_social_temp07_instagram_icon_click';
export const SHAREBLE_URL_SOCIAL_TEMP07_FACEBOOK_ICON_CLICK = 'shareble_url_social_temp07_facebook_icon_click';
export const SHAREBLE_URL_SOCIAL_TEMP07_TWITTER_ICON_CLICK = 'shareble_url_social_temp07_twitter_icon_click';
export const SHAREBLE_SHARE_TEMP07_ICON_CLICK = 'shareble_share_temp07_icon_click'
export const SHAREBLE_REVIEW_TEMP07_ICON_CLICK = 'shareble_review_temp07_icon_click'
export const SHAREBLE_URL_QR_CODE_TEMP07_ICON = 'shareble_url_qr_code_temp07_icon'
export const SHAREBLE_URL_ADD_CONTACT_TEMP07_BUTTON_CLICK = 'shareble_url_add_contact_temp07_button_click'
export const SHARE_URL_EMAIL_LINK_TEMP07_CLICK ='share_url_email_link_temp07_click';
export const SHARE_URL_LOCATION_TEMP07_CLICK = 'share_url_location_temp07_click';
export const SHARE_URL_PHONE_NUMBER_TEMP07_CLICK ='share_url_phone_number_temp07_click';
export const SHARE_URL_WEBLINK_TEMP07_CLICK = 'share_url_weblink_temp07_click';

// template08 events 
export const VIEW_TEMPLATE08 = 'view_template08'
export const SHAREBLE_URL_SOCIAL_TEMP08_WHATSAAP_ICON_CLICK ='shareble_url_social_temp08_whatsaap_icon_click';
export const SHAREBLE_URL_SOCIAL_TEMP08_YOUTUBE_ICON_CLICK = 'shareble_url_social_temp08_youtube_icon_click';
export const SHAREBLE_URL_SOCIAL_TEMP08_INSTAGRAM_ICON_CLICK = 'shareble_url_social_temp08_instagram_icon_click';
export const SHAREBLE_URL_SOCIAL_TEMP08_FACEBOOK_ICON_CLICK = 'shareble_url_social_temp08_facebook_icon_click';
export const SHAREBLE_URL_SOCIAL_TEMP08_TWITTER_ICON_CLICK = 'shareble_url_social_temp08_twitter_icon_click';
export const SHAREBLE_SHARE_TEMP08_ICON_CLICK = 'shareble_share_temp08_icon_click';
export const SHAREBLE_REVIEW_TEMP08_ICON_CLICK = 'shareble_review_temp08_icon_click';
export const SHAREBLE_URL_QR_CODE_TEMP08_ICON = 'shareble_url_qr_code_temp08_icon';
export const SHAREBLE_URL_ADD_CONTACT_TEMP08_BUTTON_CLICK = 'shareble_url_add_contact_temp08_button_click';
export const SHARE_URL_EMAIL_LINK_TEMP08_CLICK ='share_url_email_link_temp08_click';
export const SHARE_URL_LOCATION_TEMP08_CLICK = 'share_url_location_temp08_click';
export const SHARE_URL_PHONE_NUMBER_TEMP08_CLICK ='share_url_phone_number_temp08_click';
export const SHARE_URL_WEBLINK_TEMP08_CLICK = 'share_url_weblink_temp08_click';

// templates 09 events
export const VIEW_TEMPLATE09 = 'view_template09';
export const SHAREBLE_URL_SOCIAL_TEMP09_WHATSAAP_ICON_CLICK ='shareble_url_social_temp09_whatsaap_icon_click';
export const SHAREBLE_URL_SOCIAL_TEMP09_YOUTUBE_ICON_CLICK = 'shareble_url_social_temp09_youtube_icon_click';
export const SHAREBLE_URL_SOCIAL_TEMP09_INSTAGRAM_ICON_CLICK = 'shareble_url_social_temp09_instagram_icon_click';
export const SHAREBLE_URL_SOCIAL_TEMP09_FACEBOOK_ICON_CLICK = 'shareble_url_social_temp09_facebook_icon_click';
export const SHAREBLE_URL_SOCIAL_TEMP09_TWITTER_ICON_CLICK = 'shareble_url_social_temp09_twitter_icon_click';
export const SHAREBLE_SHARE_TEMP09_ICON_CLICK = 'shareble_share_temp09_icon_click';
export const SHAREBLE_REVIEW_TEMP09_ICON_CLICK = 'shareble_review_temp09_icon_click';
export const SHAREBLE_URL_QR_CODE_TEMP09_ICON = 'shareble_url_qr_code_temp09_icon';
export const SHAREBLE_URL_ADD_CONTACT_TEMP09_BUTTON_CLICK = 'shareble_url_add_contact_temp09_button_click';
export const SHARE_URL_EMAIL_LINK_TEMP09_CLICK ='share_url_email_link_temp09_click';
export const SHARE_URL_LOCATION_TEMP09_CLICK = 'share_url_location_temp09_click';
export const SHARE_URL_PHONE_NUMBER_TEMP09_CLICK ='share_url_phone_number_temp09_click';
export const SHARE_URL_WEBLINK_TEMP09_CLICK = 'share_url_weblink_temp09_click';
// templates 10 events
export const VIEW_TEMPLATE10 = 'view_template10';
export const SHAREBLE_URL_SOCIAL_TEMP10_WHATSAAP_ICON_CLICK ='shareble_url_social_temp10_whatsaap_icon_click';
export const SHAREBLE_URL_SOCIAL_TEMP10_YOUTUBE_ICON_CLICK = 'shareble_url_social_temp10_youtube_icon_click';
export const SHAREBLE_URL_SOCIAL_TEMP10_INSTAGRAM_ICON_CLICK = 'shareble_url_social_temp10_instagram_icon_click';
export const SHAREBLE_URL_SOCIAL_TEMP10_FACEBOOK_ICON_CLICK = 'shareble_url_social_temp10_facebook_icon_click';
export const SHAREBLE_URL_SOCIAL_TEMP10_TWITTER_ICON_CLICK = 'shareble_url_social_temp10_twitter_icon_click';
export const SHAREBLE_SHARE_TEMP10_ICON_CLICK = 'shareble_share_temp10_icon_click';
export const SHAREBLE_REVIEW_TEMP10_ICON_CLICK = 'shareble_review_temp10_icon_click';
export const SHAREBLE_URL_QR_CODE_TEMP10_ICON = 'shareble_url_qr_code_temp10_icon';
export const SHAREBLE_URL_ADD_CONTACT_TEMP10_BUTTON_CLICK = 'shareble_url_add_contact_temp10_button_click';
export const SHARE_URL_EMAIL_LINK_TEMP10_CLICK ='share_url_email_link_temp10_click';
export const SHARE_URL_LOCATION_TEMP10_CLICK = 'share_url_location_temp10_click';
export const SHARE_URL_PHONE_NUMBER_TEMP10_CLICK ='share_url_phone_number_temp10_click';
export const SHARE_URL_WEBLINK_TEMP10_CLICK = 'share_url_weblink_temp10_click';
export const VIEW_LOGIN_PAGE = 'view_login_page'
export const LOGIN_VERIFY_MOBILENUMBER = 'login_verify_mobilenumber'